:import("@/assets/styles/shared/modal.module.scss") {
  locl-modal__header-label: locl-modal__header-label;
  locl-modal__header-icon: locl-modal__header-icon;
}

.locl-google-map-set-point-modal {
  width: 859px !important;

  :global {
   .ant-modal-header {
      padding: 16px;
   }

    .ant-modal-body {
      padding: 0 16px 16px;
    }
  }
}


.locl-google-map-set-point-modal-content {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;

  &__address {
    display: flex;
    grid-gap: 8px;
    border: 1px solid var(--secondary-color-400);
    border-radius: 4px;
    padding: 8px;

    &__icon {
      width: 20px;
      height: 20px;
      flex: 0 0 20px;
      color: var(--gray-color-400);

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &__label {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: var(--secondary-color-500);
    }
  }

  &__map {
    position: relative;
    width: 100%;
    height: 486px;
    max-height: 75vh;
    border-radius: 4px;
    box-shadow: 0px 1px 3px 0px rgba(31, 41, 55, 0.22);
    overflow: hidden;

    &__icon {
      width: 40px;
      min-width: 40px;
      height: 40px;
      color: var(--danger-color-500);

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    grid-gap: 8px;
  }
}
