:import("@/assets/styles/landlord/components/landlord-space-card.module.scss") {
  locl-landlord-space-card: locl-landlord-space-card;
  locl-landlord-space-card__body: locl-landlord-space-card__body;
  locl-landlord-space-card__body__title: locl-landlord-space-card__body__title;
}
:import("@/assets/styles/landlord/components/landlord-event-status.module.scss") {
  locl-landlord-event-status: locl-landlord-event-status;
}
:import("@/assets/styles/landlord/shared/input.module.scss") {
  locl-input-old__label: locl-input-old__label;
  locl-input-old-wrapper: locl-input-old-wrapper;
}

.locl-ll-space-search-list {
  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
  }

  &__title {
    font-size: 24px;
    line-height: 32px;
  }

  &__search {
    display: flex;
    align-items: center;

    &__icon {
      align-self: flex-end;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .locl-input-old-wrapper {
      grid-gap: 10px;
      width: 100%;
      margin-right: 10px;

      .locl-input-old__label {
        margin-bottom: 0;
        line-height: 24px;
        font-size: 16px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
      }
    }
  }

  &__box-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__size,
    &__footfall {
      font-weight: 500;
      font-size: 14px;
      line-height: 1.6;
      margin: 0 0 0.25rem 0;
      color: rgba(0, 0, 0, 0.85);
    }

    &__price {
      font-weight: 500;
      font-size: 16px;
      line-height: 1.6;
      margin-bottom: 0.5rem;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  &__container {
    border: 1px solid #a1a1a1;
    max-height: 700px;
    margin-bottom: 20px;
    overflow: auto;

    .locl-landlord-space-card {
      padding: 1rem;

      &:not(:last-child) {
        border-bottom: 1px solid rgb(161, 161, 161);
      }

      &__body {
        width: 100%;

        &__title {
          margin-bottom: 0.5rem;
        }
      }
    }

    .locl-landlord-event-status {
      font-size: 14px;
      font-weight: 400;
    }
  }
}
