.locl-cropper-modal {
  width: 920px !important;

  :global {
    .ant-modal-body {
      padding-top: 0;
    }
  }
}

.locl-cropper-modal-content {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__controls {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    svg {
      width: 18px;
      height: 18px;
      flex: 0 0 18px;
    }
  }

  &__cropper {
    min-height: 400px;

    background-color: var(--secondary-color-300);
  }

  &__controls-crop {
    margin-left: auto;
  }
}
