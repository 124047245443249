.locl-landlord-settings-profile {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;

  &__form {
    display: flex;
    flex-direction: column;
    grid-gap: 24px;

    padding: 16px;
    background: var(--white-color-500);
    border-radius: 4px;

    &__block {
      display: flex;
      flex-direction: column;
      grid-gap: 24px;
      
      &__title {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.6;
        color: var(--secondary-color-500);
      }
    }

    &__inputs-group {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 16px;
    }

    &__footer {
      display: flex;
      justify-content: flex-end;
      grid-gap: 8px;
    }
  }

  &__delete-button {
    align-self: flex-start;
    padding: 8px 0;
    background: transparent;
    border: none;
    
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--danger-color-650);
    cursor: pointer;
    
    &:hover {
      text-decoration: underline;
    }
  }
}
