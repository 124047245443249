.locl-llp-deals-config-modal {
  &:global(.ant-modal) { width: 420px !important; }

  :global {
    .ant-modal-body { padding-top: 0 !important; }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
    }
  }
}
