:import("@/assets/styles/shared/button.module.scss") {
  locl-button: locl-button;
}

.locl-add-purchase-order-modal {
  width: 612px !important;

  :global {
    .ant-modal-header { padding-top: 24px; } 
    .ant-modal-body { padding-top: 8px; }
  }

  &__form {
    display: flex;
    flex-direction: column;
    grid-gap: 36px;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 8px;
  }
}

@media screen and (max-width: 720px) {
  .locl-add-purchase-order-modal {
    max-width: calc(100vw - 48px) !important;

    &__form { grid-gap: 24px; }
    
    &__footer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      .locl-button { width: 100%; }
    }
  }
}
