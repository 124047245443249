.locl-subscription-form {
  width: 564px;
  max-width: 100%;

  font-family: var(--secondary-font-family);

  display: flex;
  flex-direction: column;
  grid-gap: 24px;

  &__wrapper {
    padding: 24px;
    max-width: 90vw;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &__title {
      font-family: var(--secondary-font-family);
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: var(--secondary-color-500) !important;
      margin-bottom: 0;
      padding-right: 19px;
    }
  }

  &__user-types {
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    position: relative;

    &__list {
      display: grid;
      align-items: center;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 1fr;
      grid-gap: 12px;
    }

    &__item {
      width: 100%;
      height: 100%;
      min-height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px;
  
      flex-direction: column;
      grid-gap: 8px;
  
      border: 1px solid #E4E4E7;
      border-radius: 4px;
  
      cursor: pointer;
      transition: all .2s;

      &__icon {
        width: 24px;
        height: 24px;
    
        display: flex;
        align-items: center;
        justify-content: center;
    
        color: rgb(55, 65, 81) !important;
    
        transition: all .2s;
    
        svg {
          width: 100%;
          height: 100%;
        }
      }

      &__label {
        font-family: var(--secondary-font-family);
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: rgb(107, 114, 128) !important;
      
        margin: 0;
      }
    }

    &__item-active {
      background: #E7F8F7;
      border: 1px solid #E4E4E7;
    }
  }

  &__error {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    color: rgb(254, 97, 97) !important;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 0;
    height: 12px;
    max-width: 280px;
    line-height: 14px;
    font-family: var(--secondary-font-family);

    &_icon {
      flex: 0 0 16px;
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
  
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__from {
    display: flex;
    flex-direction: column;
    grid-gap: 14px;
    position: relative;

    &__label {
      font-family: var(--secondary-font-family);
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #6B7280;
      
      margin: 0;
    }
  }
  
  &__footer {
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
  }

  &__divider {
    width: calc(100% - 24px);
    height: 1px;
    background: rgb(243, 244, 246);
    margin: 0 12px;
  }

  &__success {
    width: 376px;
    max-width: 100%;

    color: #000;

    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 16px;
    padding-top: 24px;

    &__title {
      color: #000;
      text-align: center;
      font-family: var(--secondary-font-family);
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
    }

    &__subtitle {
      font-family: var(--secondary-font-family);
      color: #6B7280;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
    }

    &__icon {
      width: 32px;
      height: 32px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    :global {
      .ant-btn {
        margin: 8px 0 0 auto;
        min-width: 72px;
      }
    }
  }

  :global {
    .ant-radio-group {
      grid-row-gap: 10px;
      grid-column-gap: 24px;
      display: grid;
      flex-direction: column;
      grid-template-columns: 1fr 1fr;
    }

    .ant-btn {
      margin-right: 0;
      margin-top: -12px;
      margin-left: auto;
    }
  }
}

@media screen and (max-width: 620px) {
  .locl-subscription-form {

    &__user-types {
      &__list {
        grid-template-columns: 1fr;
      }
    }

    :global {
      .ant-radio-group {
        grid-template-columns: 100%;
      }
    }
  }
}
