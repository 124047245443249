.locl-location-space-form-preview {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;

  &__head {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
  }

  &__title {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    color: var(--secondary-color-500); 
  }

  &__subtitle {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--secondary-color-500); 
  }

  &__block {
    display: flex;
    align-items: center;
    grid-gap: 16px;
    padding: 8px;
    border: 1px solid var(--primary-color-600);
    border-radius: 4px;
    box-shadow: 0px 1px 2px 0px rgba(31, 41, 55, 0.08);
  }

  &__block-image {
    height: 56px;
    width: 90px;
    flex: 0 0 90px;
    border-radius: 4px;
    overflow: hidden;
  }

  &__block-info {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
  }

  &__block-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: var(--primary-color-600); 
  }

  &__block-subtitle {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--secondary-color-500);
  }

  &__block-button {
    margin-left: auto;
  }

  &__hint {
    display: flex;
    align-items: center;
    grid-gap: 4px;
    padding: 4px 8px;
    background: var(--secondary-color-200);
    border-radius: 6px;
    align-self: flex-start;
  
    &__icon {
      width: 24px;
      height: 24px;
      flex: 0 0 24px;
      color: var(--gray-color-500);
    }
  
    &__description {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: var(--secondary-color-500);
    }
  }
}
