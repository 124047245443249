:import("@/assets/styles/shared/button.module.scss") {
  locl-button: locl-button;
}

.locl-llp-crop-modal {
  width: 920px !important;

  :global {
    .ant-modal-body {
      padding-top: 0;
    }
  }
}

.locl-llp-crop-modal-content {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__controls {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    margin-top: 16px;

    svg {
      width: 20px;
      height: 20px;
      flex: 0 0 20px;
    }

    .locl-button {
      padding: 8px;
      box-shadow: 0px 1px 3px 0px rgba(31, 41, 55, 0.22);
    }
  }

  &__cropper-wrapper {
    padding: 8px;
    background-color: var(--primary-color-100);
    border-radius: 0px 0px 4px 4px;

    &__with-border {
      border: 1px solid var(--secondary-color-300);
    }
  }

  &__cropper {
    width: 100%;
    min-height: 400px;
  }

  &__controls-crop {
    margin-left: auto;

    display: flex;
    align-items: center;
    grid-gap: 8px;
  }

  &__description {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--secondary-color-500);
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__steps {
    display: flex;
    align-items: center;
    grid-gap: 8px;

    &__item {
      display: flex;
      align-items: center;
      grid-gap: 8px;
      padding: 8px;

      border-radius: 4px 4px 0 0;

      &__number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid var(--gray-color-800);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: var(--gray-color-800);
      }

      &__text {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: var(--gray-color-800);
      }
    }

    &__item--active {
      background: var(--primary-color-100);

      .locl-llp-crop-modal-content__steps__item {
        &__number {
          background: var(--primary-color-900);
          border-color: var(--primary-color-900);
          color: var(--white-color-500);
        }
        &__text {
          color: var(--primary-color-900);
        }
      }
    }

    &__item--passed {
      .locl-llp-crop-modal-content__steps__item {
        &__number {
          border-color: var(--primary-color-900);
          color: var(--primary-color-900);
        }
        &__text {
          color: var(--primary-color-900);
        }
      }
    }
  }

  &__tabs {
    &__header-item {
      display: flex;
      align-items: center;
      grid-gap: 8px;

      &__icon {
        color: inherit;
        width: 20px;
        height: 20px;
        flex: 0 0 20px;
        
        svg {
          width: 100%;
          height: 100%;
        }
      }
      
      &__text {
        font-size: 14px;
        font-style: normal;
        line-height: 20px;
        color: inherit;
      }
    }

    &:global(.ant-tabs) {
      :global {
        .ant-tabs-content-holder {
          display: none;
        }

        .ant-tabs-nav-list {
          border-radius: 4px 4px 0 0;
          border-top: 1px solid var(--secondary-color-300);
          border-left: 1px solid var(--secondary-color-300);
          border-right: 1px solid var(--secondary-color-300);
          overflow: hidden;
        }

        .ant-tabs-nav {
          &:before { content: none; }

          .ant-tabs-tab {
            border-radius: 0;
            padding: 8px;
            font-weight: 400;
            color: var(--gray-color-500);
  
            &.ant-tabs-tab-active {
              background-color: var(--primary-color-100) !important;
              font-weight: 500;
              color: var(--secondary-color-500);
            }
          }
        }
      }
    }
  }
}
