.locl-simple-file-preview {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
  background-color: var(--white-color-500);
  border-radius: 4px;
  border: 1px solid var(--secondary-color-300);
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  padding: 8px;

  &__text {
    color: var(--secondary-color-500) !important;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__text-input {
    width: 100%;
    border: none;
    outline: none;
  }

  &__icon {
    color: var(--danger-color-600);
    cursor: pointer;
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
    margin-left: auto;
  }
}
