:import("@/assets/styles/shared/modal.module.scss") {
  locl-modal__header-label: locl-modal__header-label;
}
:import("@/assets/styles/landlord/shared/input.module.scss") {
  locl-input-old__error: locl-input-old__error;
}
:import("@/assets/styles/landlord/components/space-search-list.module.scss") {
  locl-ll-space-search-list: locl-ll-space-search-list;
}


.locl-landlord-amend-event-modal {

  // need to rewrite from Inter to Montserrat
  * {
    font-family: var(--secondary-font-family);
  }

  &:global(.ant-modal) {
    width: 1100px !important;

    :global {
      .ant-modal-header,
      .ant-modal-content { background-color: var(--dark-color-010); }

      .ant-modal-content {
        min-height: 700px;
      }  
    }
  }

  .locl-modal__header-label {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
  }
}

.locl-landlord-amend-event-content {
  display: flex;
  flex-direction: column;
  gap: 32px;


  :global {
    .ant-tabs-tab {
      padding: 8px 16px;
      background-color: var(--white-color-500);
      border: 1px solid #f0f0f0;
  
      &.ant-tabs-tab-active {
        background-color: var(--danger-color-500);
        .ant-tabs-tab-btn { color: var(--white-color-500); }
      }
    }
  
    .ant-tabs-tab + .ant-tabs-tab { margin: 0; }
    
    .ant-tabs-ink-bar {
      background: #FE6161;
    }
  }
  
  &__adjust-form {
    margin-top: 16px;

    display: flex;
    flex-direction: column;
    gap: 24px;

    &__error {
      line-height: 15px;
      min-height: 0;
      font-size: 12px;
      color: #D05050;
    }

    &__row {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    &__price {
      display: flex;
      flex-direction: column;
      gap: 12px;

      margin-top: 26px;
    }

    &__price-row {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 16px;
    }

    &__select-reason { width: 250px !important; }

    &__input-group {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &__price-input {
      display: grid;
      grid-template-columns: auto 150px;
      align-items: center;
      gap: 8px;

      white-space: nowrap;
      justify-content: flex-end;

      .locl-input-old__error {
        grid-column: 1 / -1;
      }
    }

    &__controls {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 14px;
    }
    
    &__calendar {
      display: grid;
      grid-template-columns: 1fr 250px;
      gap: 24px;
    }

    &__calendar-statuses {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    &__info-card {
      display: flex;
      flex-direction: column;
      gap: 8px;
      
      padding-top: 12px;
    }

    &__info-card-row {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;
    }

    &__space-adjust-info {
      margin-top: 12px;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      gap: 8px;

      &__label {
        font-weight: 600;
        margin-bottom: 8px !important;
      }

      &__range-label { margin: 0; }
      &__range-value {
        margin: 0;
        font-size: 16px;
      }
    }
  }

  .locl-ll-space-search-list {
    padding: 16px;

    background-color: var(--white-color-500);
    max-width: 720px;
    margin: 0 !important;
  }
}
