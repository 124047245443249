.locl-add-billing-details-modal {
  max-width: 620px !important;
  width: 100% !important;

  :global {
    .ant-modal-body {
      max-height: 80vh;
      overflow: auto;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__loading {
      min-height: 240px;
    }

    &__error {
      display: flex;
      flex-direction: column;
      gap: 16px;

      align-items: center;
      justify-content: center;
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
    }
  }
}
