:import("@/assets/styles/shared/button.module.scss") {
  locl-button: locl-button;
}

.locl-landlord-decline-event-modal {

  &:global(.ant-modal) {
    :global {
      .ant-modal-body { padding-top: 0; }  
    }
  }

  &__description {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 32px;

    display: block;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;

    margin-top: 24px;
  }

  &__text-button.locl-button {
    font-weight: 600;
    color: var(--dark-color-400);
    background-color: transparent;
  }
}
