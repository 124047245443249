:import("@/assets/styles/shared/modal.module.scss") {
  locl-modal__header: locl-modal__header;
  locl-modal__header-label: locl-modal__header-label;
}

.locl-llp-user-delete-modal {
  &:global(.ant-modal) { width: 566px !important; }

  :global {
    .ant-modal-content {
      padding: 0;
    }

    .ant-modal-header {
      border-bottom: none;
      padding: 12px 16px 0;
      margin-bottom: 0;
    }

    .ant-modal-body {
      padding: 12px 16px;
    }
  }

  .locl-modal__header {
    grid-gap: 8px;
  }

  .locl-modal__header-label {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    color: var(--secondary-color-500);
  }
}

.locl-llp-user-delete-modal-content {
  display: flex;
  flex-direction: column;
  grid-gap: 12px;

  &__input-cause {
    &:global(.ant-input) {
      min-height: 92px;
    }
  }

  &__divider {
    height: 1px;
    width: 100%;
    background-color: var(--secondary-color-300);
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    grid-gap: 8px;
  }
}
