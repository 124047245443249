.locl-key-criteria-form {
  &__collapse {
    gap: 0;
  
    &__item {
      :global {
        .ant-collapse-header {
          background-color: var(--white-color-500);
          border-bottom: 1px solid var(--secondary-color-300);
          padding: 8px 0px !important;
          border-radius: 0 !important;
  
          .ant-collapse-expand-icon {
            border: none;
            box-shadow: none;
            transform: rotate(90deg);
          }
          
          &[aria-expanded="true"] {
            .ant-collapse-expand-icon {
              transform: rotate(180deg);
            }
          }
        }

        .ant-collapse-content-box {
          padding: 0 !important;

          display: flex;
          flex-direction: column;
          gap: 16px;

          margin-top: 16px;
        }
      }
  
      &__extra {
        font-size: 14px;
        color: var(--gray-color-800);
      }
    }
  }

  &__rules-of-space {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    margin-top: -16px;
    background-color: var(--secondary-color-200);

    &__rules__list,
    &__rules {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &__rules {
      &__list__item,
      &__input {
        display: flex;
        gap: 8px;
        align-items: flex-end;
        justify-content: flex-start;
      }

      &__input {
        margin-bottom: 24px;
      }

      &__list__item__text {
        width: 100%;
        padding: 7px 8px;
        background: var(--white-color-500);
        border: 1px solid var(--secondary-color-300);
        border-radius: 4px;
        
        font-size: 14px;
        line-height: 20px;
      }

      &__list__item__delete {
        color: var(--danger-color-600) !important;
      }
    }
  }
}
