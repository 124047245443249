.locl-create-hirer-modal {
  min-width: 620px !important;

  :global {
    .ant-modal-body { padding-top: 5px; }
    .ant-divider { margin: 12px 0 !important; }
  }

  &__success {
    padding: 24px 16px;
    border-radius: 4px;
    background-color: var(--primary-color-100);
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;

    &__icon {
      width: 26px;
      height: 26px;
      flex: 0 0 26px;
      color: var(--primary-color-500);
    }

    &__message {
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--secondary-color-500);
    }

    &__message-password {
      cursor: pointer;
      color: var(--primary-color-500);

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 18px;
    width: 100%;

    &__inputs-group {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;

      width: 100%;
    }

    &__info {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: var(--gray-color-500);
      opacity: .6;
    }
  }
}
