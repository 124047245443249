.locl-llp-deals-amend-modal {
  &:global(.ant-modal) { width: 864px !important; }

  :global {
    .ant-modal-header {
      border-bottom: 1px solid var(--secondary-color-300) !important;
    }

    .ant-modal-body {
      padding: 16px !important;
    }

    .locl-llp-deals-amend-modal__space-select__option {
      .locl-select-label {
        width: 100%;
      }
    }
  }

  &__steps {
    padding: 0;
    gap: 12px;
  }

  &__price-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    min-height: 560px;

    &__activity-info {
      display: flex;
      flex-direction: column;
      grid-gap: 16px;

      &__title {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        color: var(--secondary-color-800);
      }
    }

    &__content {
      flex: 1 1 auto;

      display: flex;
      flex-direction: column;
      gap: 16px;
      
      &__step {
        display: flex;
        flex-direction: column;
        gap: 12px;

        &__title {
          font-size: 18px;
          line-height: 28px;
          font-weight: 500;
        }
      }

      .locl-llp-deals-amend-modal-activity-info {
        margin-bottom: 8px;
      }
    }

    &__actions {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;

      &__right {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-left: auto;
      }

      &__error {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: var(--danger-color-650);
      }
    }
  }
}

.locl-llp-deals-amend-modal-select-option {
  display: flex;
  align-items: center;
  gap: 12px;

  &__image {
    border-radius: 4px;
    object-fit: cover;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &__title {
      font-size: 16px;
      line-height: 24px;
      color: var(--secondary-color-500);
    }

    &__description {
      font-size: 14px;
      line-height: 20px;
      color: var(--gray-color-500);
    }
  }

  &__price {
    margin-left: auto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75;
    color: var(--primary-color-500);
  }
}

.locl-llp-deals-amend-modal-calendar {
  &__actions {
    display: grid;
    grid-template-columns: 1fr 1fr 220px;
    gap: 12px;

    width: 100%;
  }

  &__main {
    display: flex;
    gap: 12px;

    &__calendar {
      display: grid;
      grid-template-columns: 600px;

      &__error {
        display: flex;
        gap: 10px;
        align-items: center;
        color: var(--danger-color-600);
      }

      &__error-icon {
        flex: 0 0 16px;
        width: 16px;
        height: 16px;
      }

      &__error-text {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  &__legends {
    width: 220px;

    display: flex;
    flex-direction: column;
    gap: 8px;
    
    padding: 16px;
    border-radius: 4px;
    background-color: var(--gray-color-300);

    &__title {
      font-size: 16px;
      line-height: 24px;
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 24px;

      &__item {
        display: flex;
        align-items: center;
        gap: 8px;

        &__color {
          width: 20px;
          height: 20px;
          flex: 0 0 20px;
          border-radius: 4px;

          &_available {
            background-color: var(--white-color-500);
          }

          &_lo-live-option {
            background-color: rgba(205, 124, 46, 1);
          }

          &_lo-live-booking {
            background-color: rgba(194, 88, 85, 1);
          }

          &_non-lo-live-option {
            background-color: rgba(238, 196, 96, 1);
          }

          &_non-lo-live-booking {
            background-color: rgba(120, 52, 80, 1);
          }

          &_unavailable {
            background-image: linear-gradient(-45deg, var(--secondary-color-400) 25%, transparent 25%, transparent 50%, var(--secondary-color-400) 50%, var(--secondary-color-400) 75%, transparent 75%, transparent);
            background-size: 11px 11px;
          }

          &_always-closed {
            background-color: var(--secondary-color-400);
          }
        }

        &__label {
          font-size: 16px;
          line-height: 20px;
          white-space: nowrap;
        }
      }
    }
  }
}

.locl-llp-deals-amend-modal-activity-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 16px;
  padding: 12px 8px;

  border-radius: 4px;
  border: 1px solid var(--secondary-color-400);
  background: var(--color-white-500);

  box-shadow: 0px 1px 2px 0px rgba(31, 41, 55, 0.08);

  &__space-info {
    display: flex;
    align-items: center;
    grid-gap: 12px;
    flex: 1 0 0;
    min-width: 1px;
    max-width: max-content;

    &__img {
      width: 50px;
      height: 48px;
      flex: 0 0 50px;
      border-radius: 4px;
      object-fit: cover;
    }

    &__content {
      min-width: 1px;
    }

    &__name {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.45;
      color: var(--secondary-color-500);

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__dimension {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.3;
      color: var(--gray-color-500);
    }
  }

  &__date {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--secondary-color-500);
  }

  &__price {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 0 8px;
    align-items: center;
    min-width: max-content;

    &__label {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.75;
      color: var(--secondary-color-500);
      text-align: right;
    }

    &__value {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.75;
      color: var(--primary-color-500);
      text-align: left;
    }
  }
}
