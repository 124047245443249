.locl-download-campaign-pack-modal {
  &:global(.ant-modal) { width: max-content !important; }

  :global {
    .ant-modal-body {
      padding: 0 16px 16px 16px !important;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    
    .ant-modal-footer {
      padding: 0 16px 16px 16px;
      margin-top: 0;

      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &__label {
    color: var(--gray-color-500);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 252px);
    gap: 16px;

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(1, 200px);
      align-items: center;
      justify-content: center;
    }
  }

  &__list-item {
    border-radius: 4px;
    border: 1px solid var(--secondary-color-300);
    box-shadow: 0px 1px 3px 0px rgba(31, 41, 55, 0.22);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    padding: 24px;


    @media screen and (max-width: 768px) {
      padding: 12px;
      gap: 12px;
    }

    cursor: pointer;
    transition: all 0.3s ease;

    &_active {
      border-color: var(--primary-color-500);
      background-color: var(--primary-color-100);
    }

    &__label {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      color: var(--secondary-color-500);
      white-space: nowrap;

      @media screen and (max-width: 768px) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &__icon {
      width: 100px;
      height: 100px;

      @media screen and (max-width: 768px) {
        width: 52px;
        height: 52px;
      }
    }
  }
}
