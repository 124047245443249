.locl-steps {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 12px 16px;
  background: var(--white-color-500);
  border-radius: 4px;

  &_bordered {
    border: 1px solid var(--secondary-color-300);
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 16px;

    color: var(--gray-color-800);

    &__info {
      display: flex;
      align-items: center;
      grid-gap: 8px;
    }

    &__number {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 20px;
      height: 20px;
      flex: 0 0 20px;
      border-radius: 50%;
      border: 1px solid var(--gray-color-800);

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    &__label {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      white-space: nowrap;
    }

    &__icon {
      width: 24px;
      height: 24px;
      color: var(--secondary-color-400);
    }

    &__icon-active {
      color: var(--primary-color-900);
    }
  }

  &__item-active {
    color: var(--primary-color-900);

    .locl-steps__item__number {
      border-color: var(--primary-color-900);
      background: var(--primary-color-900);
      color: var(--white-color-500);
    }
  }

  &__item-completed {
    color: var(--primary-color-900);

    .locl-steps__item {
      &__number {
        border-color: var(--primary-color-900);
      }
    }
  }

  &__loading {
    &:global(.ant-skeleton-element .ant-skeleton-input ),
    &:global(.ant-skeleton-element) {
      height: 24px !important;
      min-width: 20px !important;
      width: 100% !important;
      border: 1px solid var(--gray-color-300);
    }
  }
}

@media screen and (max-width: 767px) {
  .locl-steps__item__label { display: none; }
}
