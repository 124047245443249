:import("@/assets/styles/landlord/shared/input.module.scss") {
  locl-input-old-wrapper: locl-input-old-wrapper;
}

.locl-landlord-cancel-event-modal {
  width: auto !important;
  max-width: 60vw !important;

  .locl-modal__header-label {
    font-weight: 700;
    font-size: 20px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__row {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &__row-title {
      font-weight: 700;
      font-size: 16px;
      white-space: nowrap;
    }

    &__row-hint { font-size: 13px; }

    :global {
      .locl-landlord-cancel-event-modal-content-select {
        width: 300px !important;

        &__menu {
          width: 300px !important;
          max-width: 300px !important;
        }
      }
    }
    &__footer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }

    &__error {
      line-height: 15px;
      min-height: 0;
      font-size: 12px;
      color: #D05050;
    }

    .locl-shared-select:has(&__select) { width: 300px !important; }
    .locl-input-old-wrapper { gap: 8px }
  }

  :global {
    .ant-modal-body { min-height: 150px; }

    .locl-ll-space-search-list__title {
      font-weight: 700;
      font-size: 20px;
    }
  }
}
