.locl-date-picker {
  width: 100%;
  height: 36px;

  background: var(--white-color-500);
  border: 1px solid var(--secondary-color-300);
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border-radius: 4px;

  color: var(--secondary-color-500);
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  &:global(.ant-picker-focused.ant-picker) {
    border: 1px solid var(--primary-color-500) !important;
    box-shadow: 0px 0px 0px 3px rgb(231, 248, 247) !important;
  }

  &:hover {
    border: 1px solid var(--gray-color-400);
    box-shadow: 0px 0px 0px 3px rgb(229, 231, 235);
  }

  &__popup {
    :global {
      .ant-picker-panel { border-bottom: none; }

      .ant-picker-cell:before {
        height: 37px;
      }

      .ant-picker-cell-today {
        &.ant-picker-cell .ant-picker-cell-inner {
          font-weight: 600;

          &:before { border: none; }
        }
      }

      .ant-picker-cell:not(.ant-picker-cell-in-view) { opacity: .5; }

      .ant-picker-cell-disabled {
        .ant-picker-cell-inner {
          color: #ccc !important;
        }

        &::before {
          background-color: transparent !important;
        }
      }
  
      .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
      .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
      .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
        color: var(--white-color-500) !important;
        background-color: var(--primary-color-500) !important;
        font-weight: 600;
      }

      .ant-picker-cell .ant-picker-cell-inner {
        align-items: center;
        display: flex;
        font-family: var(--main-font-family);
        height: 37px;
        justify-content: center;
        margin: 0;
        width: 40px;

        color: #19181a;
        font-weight: 400;
      }

      .ant-picker-panel-container .ant-picker-presets {
        padding: 8px;
        width: 160px;
        margin-top: -84px;

        ul > li {
          border-radius: 2px;
          cursor: pointer;
          font-family: var(--main-font-family);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          padding: 8px;
          margin: 0 !important;
        }
      }

      .ant-picker-today-btn { display: none; }
    }
  }
}
