.locl-landlord-space-card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;

  &__body {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 16px;

    &__info-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
    }

    &__info {
      display: flex;
      flex-direction: column;

      height: 100%;
    }

    &__title {
      cursor: pointer;
      font-size: 16px;
      line-height: 1.6;
      font-weight: 700;
      display: block;
      white-space: nowrap;

      color: var(--dark-color-500);
    }

    &__image {
      width: 125px;
      height: 125px;
    }

    &__image-tag {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__location-title {
      grid-column: 1/3;
      font-size: 16px;
      line-height: 1.6;
      color: #252525;
      font-weight: 500;
      text-transform: capitalize;
    }
  }  
}
