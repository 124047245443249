.locl-attach-add-file {
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;

    &__label {
      width: 100%;
    }

    &__button {
      position: relative;
    }
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 8px;

    margin-top: 16px;
  }
}
