:import("@/assets/styles/shared/button.module.scss") {
  locl-button: locl-button;
}

.locl-add-edit-campaign-modal {
  width: 612px !important;

  :global {
    .ant-modal-header { padding: 24px; }
    .ant-modal-body { padding: 0 24px 24px; }
    
    .ant-modal-content { min-height: 400px; }
  }
}

.locl-add-edit-campaign-modal-form {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    grid-gap: 8px;

    padding: 12px 0 0 0;
    border-top: 1px solid var(--gray-color-300);
  }
}

@media screen and (max-width: 720px) {
  .locl-add-edit-campaign-modal {
    width: 100vw !important;
    max-width: 100vw !important;
    margin: 0 !important;
    padding: 0;
    top: 0;

    .locl-modal__header-icon { align-self: flex-start; }

    :global {
      .ant-modal-content {
        height: var(--window-height, 100vh);
        border-radius: 0 !important;
        display: flex;
        flex-direction: column;
      }
  
      .ant-modal-header { padding: 24px 16px; }
  
      .ant-modal-body {
        min-width: 100%;
        padding: 0 16px !important;
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: auto;
      } 
    }
  }

  .locl-add-edit-campaign-modal-form {
    flex: 1;

    &__footer {
      position: sticky;
      bottom: 0;
      padding-bottom: 24px;
      background: var(--white-color-500);

      margin-top: auto;

      display: grid;
      grid-template-columns: repeat(2, 1fr);

      &:before {
        content: " ";
        position: absolute;
        z-index: 2;
        bottom: 100%;
        left: 0;
        width: 100%;
        height: 20px;
        display: block;

        background: linear-gradient(to bottom, rgba(255, 255, 255,  0.3), rgba(255, 255, 255));
      }

      .locl-button { width: 100%; }
    }
  }
}
