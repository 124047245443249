:import("@/assets/styles/components/steps.module.scss") {
  locl-steps: locl-steps;
}

:import("@/assets/styles/shared/checkbox.module.scss") {
  locl-checkbox: locl-checkbox;
}

:import("@/assets/styles/shared/button.module.scss") {
  locl-button: locl-button;
}

.locl-duplicate-campaign-modal {
  &:global(.ant-modal) {
    width: 642px !important;

    :global {
      .ant-modal-header { padding: 24px 24px 8px; }
      .ant-modal-body {
        padding: 0 24px;
        background: var(--white-color-500);
        overflow: auto;
        display: flex;
        flex-direction: column;
        padding-bottom: 0;
        max-height: 90vh;
        min-height: 100px;
      }

      .ant-modal-content {
        border-radius: 8px;
        overflow: hidden;
      }
    }
  }
}

.locl-duplicate-campaign-modal-content {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;

  &__steps {
    &.locl-steps {
      justify-content: flex-start;
      grid-gap: 16px;
      padding: 12px 0;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    grid-gap: 8px;
    padding-top: 16px;
    
    &__back {
      margin-right: auto;
    }
  }

  &__footer {
    position: sticky;
    bottom: 0;
    padding-bottom: 24px;
    background: var(--white-color-500);

    &:before {
      content: " ";
      position: absolute;
      z-index: 2;
      bottom: 100%;
      left: 0;
      width: 100%;
      height: 16px;
      display: block;

      background: linear-gradient(to bottom, rgba(255, 255, 255,  0.3), rgba(255, 255, 255));
    }
  }
}

.locl-duplicate-campaign-modal-spaces {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;

  &__select-all {
    &.locl-checkbox {
      color: var(--primary-color-700);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
  }
}

.locl-duplicate-campaign-modal-spaces-box {
  padding: 8px;
  background: var(--white-color-500);
  border: 1px solid var(--gray-color-300);
  border-radius: 4px;

  display: flex;
  align-items: center;
  grid-gap: 16px;

  &__image-wrapper {
    position: relative;
    width: 97px;
    height: 84px;
    min-width: 97px;
    flex: 0 0 97px;
    border-radius: 4px;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__checkbox {
    position: absolute;
    top: 2px;
    left: 2px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    min-width: 1px;
  }

  &__location {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
    color: var(--secondary-color-500);
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
  }

  &__name {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 1;
    color: var(--secondary-color-500);
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
  }

  &__price {
    margin-top: 4px;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 0.32px;
    color: var(--primary-color-650);

    &_fx {
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0.2px;
    }
  }
}

@media screen and (max-width: 767px) {
  .locl-duplicate-campaign-modal {
    &:global(.ant-modal) {
      width: 100vw !important;
      max-width: 100vw !important;
      margin: 0 !important;
      padding: 0;
      top: 0;

      :global {
        .ant-modal-content {
          height: var(--window-height, 100vh);
          border-radius: 0;
          display: flex;
          flex-direction: column;
        }

        .ant-modal-body {
          flex: 1;
        }
      }
    }
  }

  .locl-duplicate-campaign-modal-content {
    height: 100%;

    &__body {
      flex: 1 1 0;
    }

    &__footer {
      padding-bottom: 16px;
      margin-top: auto;

      .locl-button { width: 100%; }
    }
  }  
}
