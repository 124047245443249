.locl-video-modal {
  &:global(.ant-modal) {
    width: 1140px !important;

    :global {
      .ant-modal-content {
        padding: 0;
        border-radius: 0;
        background: transparent;
      }
      .ant-modal-header {
        padding: 10px 20px;
        margin-bottom: 0;
        border-radius: 0;
      }

      .ant-modal-body {
        background: var(--white-color-500);
      }
    }
  }

  &__container {
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__video {
    height: 100% !important;
    width: 100% !important;
  }
}


@media screen and (max-width: 620px) {
  .locl-video-modal {
    &:global(.ant-modal) {
      width: 100vw !important;
      max-width: 100vw !important;
      margin: 0 !important;
      padding: 0;
      top: 0;

      :global {
        .ant-modal-content {
          border-radius: 0;
        }
      }
    }
  }
}
