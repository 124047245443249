.locl-radio-group {
  :global {
    .ant-radio-wrapper {
      font-family: var(--main-font-family);

      .ant-radio-checked .ant-radio-inner {
        border-color: var(--primary-color-500);
        background: var(--primary-color-100);

        &::after {
          background: var(--primary-color-500);
        }
      } 
    }
    
    .ant-radio-wrapper:hover .ant-radio-inner {
      border-color: var(--primary-color-500);
    }
  }
}

.locl-radio {
  :global {
    .ant-radio-checked {
      .ant-radio-inner {
        border-color: var(--primary-color-500);
        background-color: var(--white-color-500);
  
        &::after {
          background: var(--primary-color-500);
        }
      } 
    }
  }
}
