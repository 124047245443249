:import("@/assets/styles/components/label-error-provider.module.scss") {
  locl-label-error-provider: locl-label-error-provider;
  locl-label-error-provider__label: locl-label-error-provider__label;
}

:import("@/assets/styles/shared/modal.module.scss") {
  locl-modal: locl-modal;
  locl-modal__header: locl-modal__header;
}

.locl-landlord-settings-team {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;

  &__actions { align-self: flex-start; }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__tab-label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }

  &__tab-notification {
    min-width: 18px;
    height: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    background-color: var(--primary-color-100);
    color: var(--primary-color-700);
  }

  &__filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 16px;

    &__search {
      width: 364px;
    }

    &__search-icon {
      width: 20px !important;
      height: 20px !important;
      flex: 0 0 20px !important;
    }

    &__roles {
      position: relative;

      .locl-label-error-provider__label {
        position: absolute;
        top: 50%;
        left: 8px;
        transform: translateY(-50%);
        z-index: 10;
        color: var(--secondary-color-500);
        font-weight: 400;
      }

      :global {
        .locl-landlord-settings-team-filter-roles-select__value-container {
          margin-left: 49px;
          padding-left: 4px;
          color: var(--secondary-color-500);
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    padding: 16px;
    border-radius: 4px;

    background: var(--white-color-500);
  }

  &__list {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;

    .locl-landlord-settings-team-card {
      padding-bottom: 16px;
      border-bottom: 1px solid var(--secondary-color-300);
    }
  }

  &__content-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: auto;
    gap: 16px;
  }

  &__content-show-select {
    &.locl-label-error-provider {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
    }

    .locl-label-error-provider {
      &__label {
        font-size: 14px;
        line-height: 20px;
        color: var(--secondary-color-500);
        font-weight: 400;
      }
    }

    .locl-landlord-settings-team-show-select {
      width: 120px;
    }
  }
}


.locl-landlord-settings-team-card {
  display: grid;
  grid-template-columns: 340px 1fr;
  grid-gap: 100px;

  &__main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    grid-gap: 10px;
  }

  &__secondary {
    display: grid;
    grid-template-columns: 1fr 68px;
    grid-gap: 16px;
    align-items: center;
  }

  &__info {
    display: flex;
    flex-direction: row;
    grid-gap: 8px;
    align-items: center;
    min-width: 1px;
  }

  &__contact {
    display: flex;
    flex-direction: column;
    overflow-wrap: anywhere;
  }

  &__name {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--secondary-color-500);
  }

  &__email {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-transform: lowercase;
    color: var(--gray-color-500);
  }

  &__type {
    display: flex;
    align-items: center;
    grid-gap: 4px;
    min-width: 90px;
    flex: 0 0 90px;

    &__icon {
      min-width: 16px;
      width: 16px;
      height: 16px;
      flex: 0 0 16px;
      color: var(--gray-color-500);
    }

    &__text {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: var(--secondary-color-500);
    }
  }

  &__locations-list {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 8px;

    &__show-more {
      cursor: pointer;
    }
  }

  &__actions {
    display: flex;
    grid-gap: 8px;
    padding: 8px 10px;

    &__button {
      border: none;
      padding: 0;
      margin: 0;
      
      background-color: transparent;
      
      display: flex;
      align-items: center;
      justify-content: flex-start;
    
      cursor: pointer;

      &__icon {
        min-width: 20px;
        width: 20px;
        height: 20px;
      }
    }

    &__button-edit {
      .locl-landlord-settings-team-card__actions__button__icon {
        color: var(--gray-color-500);
      }
    }

    &__button-delete {
      .locl-landlord-settings-team-card__actions__button__icon {
        color: var(--danger-color-500);
      }
    }
  }
}

.locl-location-listing-modal {
  width: 612px !important;

  &.locl-modal {
    :global {
      .ant-modal-header { padding: 24px; } 
      .ant-modal-body { padding: 0 24px 24px; }
    }

    .locl-modal__header {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 8px;
  }
}
