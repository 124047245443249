:import("@/assets/styles/shared/button.module.scss") {
  locl-button: locl-button;
}

.locl-delete-conformation {
  width: 424px !important;

  :global {
    .ant-modal-header { padding-bottom: 0; }
    .ant-modal-body { padding-top: 16px; }
  }
  
  &__form {
    display: flex;
    flex-direction: column;
    grid-gap: 24px;

    &__footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      border-top: 1px solid var(--gray-color-300);
      padding-top: 12px;

      grid-gap: 8px;

      .locl-delete-conformation__button-confirm {
        &:disabled {
          opacity: 0.5;
          background: var(--danger-color-500);
          color: var(--white-color-500);
        }
      }
    }
  }

  &__message-text {
    color: var(--secondary-color-500);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  &__form-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 16px;

    margin: 0 auto;
    text-align: center;

    &__icon {
      width: 32px;
      height: 32px;

      display: flex;
      align-items: center;
      justify-content: center;

      color: var(--danger-color-700);

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &__sub-title,
    &__title {
      font-family: var(--main-font-family);
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;

      margin: 0;
      padding: 0;
    }

    &__sub-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      color: var(--secondary-color-500);
    }

    &__link {
      color: var(--gray-color-500);
      text-decoration: underline;
    }

  }
}

@media screen and (max-width: 720px) {
  .locl-delete-conformation {
    &:global(.ant-modal) {
      width: 100vw !important;
      max-width: 100vw !important;
      margin: 0 !important;
      padding: 0;
      top: 0;

      :global {
        .ant-modal-content {
          height: var(--window-height, 100vh);
          display: flex;
          flex-direction: column;
          border-radius: 0 !important;
        }

        .ant-modal-body {
          min-width: 100%;
          flex: 1;
          display: flex;
          flex-direction: column;
          padding-bottom: 0;
          overflow: auto;

          background: var(--white-color-500);
        }
      }
    }

    &__form {
      flex: 1;

      &__footer {
        position: sticky;
        bottom: 0;
        margin-top: auto;
        padding: 12px 0 16px;
        background: var(--white-color-500);

        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .locl-button { width: 100%; }
      }
    }
  }
}


// :global {
  
//   .ant-modal-header { padding: 16px 16px 24px; }

//   .ant-modal-body {
//     min-width: 100%;
//     flex: 1;
//     max-height: 100%;
//     padding: 0 16px;
//   }
// }
