.locl-upload {
  &__upload-component {
    display: flex;
    flex-direction: column;
    width: 100%;

    :global {
      .ant-upload-list-picture {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &:not(:empty) {
          margin-top: 12px;
        }
  
        .ant-upload-list-item {
          border: none;
          margin-top: 0;
          border-radius: 4px;
          overflow: hidden;
          transition: background 0.3s;
  
          @media (hover: hover) {
            &:hover { background: var(--secondary-color-200); }
          }
        }
  
        .ant-upload-list-item-thumbnail {
          width: 40px;
          height: 40px;
          border-radius: 4px;
          opacity: 1;
          overflow: hidden;
        }
  
        .ant-upload-list-item-name {
          padding: 0 16px;
        
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          color: var(--secondary-color-500);
  
          
          max-width: calc(100% - 40px - 24px);
        }
      }
  
      .ant-upload.ant-upload-select {
        width: 100%;
      }
  
      .ant-upload-list-item-action {
        svg {
          font-size: 16px;
          color: var(--danger-color-500);
        }
      }
    }
  }

  &__drop-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: var(--main-font-family);

    background: var(--secondary-color-200);
    border-radius: 4px;
    border: 1px dashed var(--secondary-color-300);
    padding: 23px;
    cursor: pointer;

    transition: background 0.3s, border 0.3s;

    @media (hover: hover) {
      &:hover {
        background: var(--primary-color-100);
        border-color: var(--primary-color-500);
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      grid-gap: 4px;
    }

    &__label {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--secondary-color-500);
    }
    
    &__hint {
      font-size: 14px;
      line-height: 20px;
      color: var(--gray-color-500);
      max-width: 350px;
      display: inline-block;
    }
    
    &__button {
      font-size: 14px;
      padding: 6px 16px;
      display: flex;
      align-items: center;
      background: var(--white-color-500);
      border: 1px solid var(--secondary-color-300);
      box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
      border-radius: 4px;
      color: var(--secondary-color-500);
      cursor: pointer;

      &__icon {
        width: 16px;
        height: 16px;
        margin-right: 8px;

        color: var(--gray-color-500);
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .locl-upload {
    &__drop-area {
      flex-direction: column;
      align-items: flex-start;
      grid-gap: 26px;

      &__info { max-width: 230px; }

      &__button {
        width: 100%;
        justify-content: center;
      }
    }
  }
}
