:import("@/assets/styles/components/Form/subscription-form.module.scss") {
  locl-subscription-form__wrapper: locl-subscription-form__wrapper;
  locl-subscription-form__footer: locl-subscription-form__footer;
  locl-subscription-form__header__title: locl-subscription-form__header__title;
  locl-subscription-form: locl-subscription-form;
}

:import("@/assets/styles/shared/button.module.scss") {
  locl-button: locl-button;
}


.locl-subscription-modal {
  &:global(.ant-modal) {
    width: auto !important;

    :global {
      .ant-modal-content {
        padding: 0;
        border-radius: 8px;
      }

      .ant-modal-header {
        height: 0;
        margin: 0;
        padding: 0;
      }

      .ant-modal-body {
        background-color: var(--white-color-500);
      }

      .locl-modal__header-icon {
        position: absolute;
        right: 24px;
        top: 24px;
      }
    }
  }
}

@media screen and (max-width: 620px) {
  .locl-subscription-modal {
    &:global(.ant-modal) {
      width: 100vw !important;
      max-width: 100vw !important;
      margin: 0 !important;
      padding: 0;
      top: 0;

      :global {
        .ant-modal-content {
          height: var(--window-height, 100vh);
          border-radius: 0;
        }

        .ant-modal-body {
          min-height: 100%;
          display: flex;
        }
      }
    }

    .locl-subscription-form {
      min-height: 100%;

      &__wrapper {
        padding: 0;
        max-width: 100%;
        min-height: 100%;
        margin-bottom: -24px;
      }

      &__header__title {
        padding-right: 32px;
      }

      &__footer {
        position: sticky;
        bottom: 0;
        left: 0;
        right: 0;
        padding-bottom: 24px;
        background-color: var(--white-color-500);
        margin-top: auto;
  
        &:before {
          content: " ";
          position: absolute;
          z-index: 2;
          bottom: 100%;
          left: 0;
          width: 100%;
          height: 30px;
          display: block;
  
          background: linear-gradient(to bottom, rgba(255, 255, 255,  0.3), rgba(255, 255, 255));
        }

        .locl-button {
          width: 100%;
        }
      }
    }
  }
}
