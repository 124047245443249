.locl-radio-card {
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 16px;
  border-radius: 4px;

  border: 1px solid var(--secondary-color-400);
  background-color: transparent;
  color: var(--gray-color-500);

  &_checked {
    border: 1px solid var(--primary-color-500);
    background-color: var(--primary-color-100);
    color: var(--secondary-color-500);
  }

  &__description {
    line-height: 20px;
  }

  &__coming-soon-label {
    display: flex;
    align-items: center;
    gap: 8px;

    &__badge {
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      padding: 0 2px;
      color: var(--secondary-color-500);

      background-color: var(--primary-color-200);
      border-radius: 4px;
    }
  }
}
