:import("@/assets/styles/shared/button.module.scss") {
  locl-button: locl-button;
  locl-button_type-tertiary: locl-button_type-tertiary;
}

:import("@/assets/styles/components/card-carousel.module.scss") {
  locl-card-carousel: locl-card-carousel;
  locl-card-carousel__slide: locl-card-carousel__slide;
}

:import("@/assets/styles/components/space-card.module.scss") {
  locl-space-card: locl-space-card;
  locl-space-card__body: locl-space-card__body;
  locl-space-card__body__carousel: locl-space-card__body__carousel;
  locl-space-card__price: locl-space-card__price;
  locl-space-card__price__with-fx: locl-space-card__price__with-fx;
  locl-space-card__price__indicate-fx: locl-space-card__price__indicate-fx;
  locl-space-card__main-info: locl-space-card__main-info;
}

:import("@/assets/styles/containers/floor-plan/index.module.scss") {
  locl-floor-plan: locl-floor-plan;
  locl-floor-plan-mark: locl-floor-plan-mark;
  locl-floor-plan__map: locl-floor-plan__map;
  locl-floor-plan__image-map : locl-floor-plan__image-map ;
  locl-floor-plan__content: locl-floor-plan__content;
  locl-floor-plan__pagination: locl-floor-plan__pagination;
  locl-floor-plan__name: locl-floor-plan__name;
}

:import("@/assets/styles/shared/dropdown/dropdown-button.module.scss") {
  locl-dropdown-inner-button: locl-dropdown-inner-button;
  locl-dropdown-inner-button__icon: locl-dropdown-inner-button__icon;
  locl-dropdown-inner-button__value: locl-dropdown-inner-button__value;
  locl-dropdown__inner: locl-dropdown__inner;
}

:import("@/assets/styles/components/collapse.module.scss") {
  locl-collapse: locl-collapse;
}

:import("@/assets/styles/shared/modal.module.scss") {
  locl-modal__header-label: locl-modal__header-label;
}

:import("@/assets/styles/containers/location-space-details-card.module.scss") {
  locl-location-space-details-card__header: locl-location-space-details-card__header;
  locl-location-space-details-card__header__title: locl-location-space-details-card__header__title;
}

:import("@/assets/styles/components/restricted-view.module.scss") {
  locl-restricted-view: locl-restricted-view;
  locl-restricted-view__title: locl-restricted-view__title;
}

.locl-location-floor-plan {
  width: 100%;
  height: max-content;

  position: relative;
  padding: 24px;
  background-color: var(--white-color-500);

  display: flex;
  flex-direction: column;
  grid-gap: 8px;

  border-radius: 4px;

  &__head {
    display: flex;
    justify-content: space-between;
  }

  &__body {
    position: relative;
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
  }

  &__content {
    position: relative;
    width: 100%;
    height: max-content;
  }

  &__title {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    color: var(--secondary-color-500);
  }

  &__space-type {
    :global {
      .css-turnqp-control {
        &:hover {
          box-shadow: 0px 0px 0px 3px var(--primary-color-100);
          border: 1px solid var(--primary-color-500);
        }
      }
    }
  }

  &__button {
    &.locl-button {
      padding: 8px;
    }
  }

  &__button-icon {
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
  }

  :global {
    .ant-dropdown-trigger {
      position: absolute;
      top: 12px;
      right: 12px;
      z-index: 2;
  
      .locl-dropdown-inner-button {
        max-width: none;
        width: 280px;
      }
    }
  }

  &__map {
    width: 100%;
    height: 100%;
    max-width: 690px;
    position: relative;

    .locl-floor-plan-mark { position: absolute; }
  }

  &__image-map {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 8px;

    position: absolute;
    bottom: 12px;
    right: 12px;
    z-index: 2;
  }

  &__controls-btn {
    width: 32px;
    height: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;

    color: var(--dark-color-500);
    border: 1px solid var(--secondary-color-300);
    background-color: var(--white-color-500);
    box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);

    cursor: pointer;

    &:hover, &:focus {
      color: var(--secondary-color-500);
      border-color: var(--secondary-color-300);
      background: var(--secondary-color-200);
      border: 1px solid var(--secondary-color-400);
    }
  }

  &__controls-btn-image {
    width: 20px;
    height: 20px;
  }

  &__mark {
    width: 32px;
    height: 28px;
    position: absolute;
  
    background-color: var(--danger-color-500);
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.3);
  
    border-radius: 4px;
  
    color: var(--white-color-500);
  
    display: flex;
    align-items: center;
    justify-content: center;
  
    &::after {
      position: absolute;
      content: " ";
      width: 0; 
      height: 0; 
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      top: 100%;
      
      border-top: 5px solid var(--danger-color-500);
    }

    &_media {
      background-color: var(--primary-color-450);

      &::after {
        border-color: var(--primary-color-450);
      }
    }
  
    &__icon {
      width: 18px;
      height: 18px;
  
      display: flex;
      align-items: center;
      justify-content: center;
  
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__name {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--secondary-color-500);
  }

  &__pagination {
    display: flex;
    align-items: center;
    justify-content: center;

    :global {
      .ant-pagination-prev,
      .ant-pagination-next {
        display: none;
      }
    }
  }

  .locl-restricted-view {
    left: -24px;
    right: -24px;
  }

  .locl-restricted-view__title {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  }
}

.locl-location-page-spaces-container {
  display: flex;
  flex-direction: column;

  grid-gap: 16px;

   &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    color: var(--secondary-color-500);
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
  }
}

.locl-location-page-spaces-container-body {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;

  &__title {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.6;
    color: var(--secondary-color-500);
  }

  &__spaces-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    max-width: 100%;
  }
}

.locl-location-page-spaces-container-filters {
  position: sticky;
  top: 72px;
  z-index: 11;
  padding: 16px 0;
  background-color: var(--gray-color-300);
  margin: -16px 0;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  grid-gap: 36px;

  &__more {
    &.locl-button.locl-button_type-tertiary {
      color: var(--secondary-color-500);

      &:global(.ant-btn:not(:disabled):not(.ant-btn-disabled):hover) {
        color: var(--primary-color-500);
        border-color: transparent;
        background: transparent;
        box-shadow: none;
      }
    }
  }

  &__list {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 8px;
    flex-wrap: wrap;

    &__button { margin-left: auto; }

    &_show-all {
      .locl-location-page-spaces-container-filters__item {
        &:nth-child(n + 2) {
          display: block;
        }
      }
    }

    .locl-location-page-spaces-container-filters__item {
      &:nth-child(n + 2) {
        display: none;
      }
    }
  }

  &__item {
    &__purpose__button {
      color: var(--primary-color-600) !important;
    }

    &__clear {
      &.locl-button {
        border: none;
      }
    }
  }

  &__collapse {
    width: 100%;

    &__header {
      position: relative;
      display: flex;
      align-items: center;
      grid-gap: 4px;

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: var(--secondary-color-500);
      text-align: center;

      &__icon {
        width: 20px;
        height: 20px;
        flex: 0 0 20px;
      }

      &__badge {
        position: absolute;
        right: -5px;
        top: -5px;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 1px solid var(--white-color-500);
        background: var(--primary-color-900);
        color: var(--white-color-500);

        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
      }
    }

    :global {
      .ant-collapse-header {
        padding: 8px !important;
        justify-content: center !important;
        background: var(--white-color-500) !important;
      }

      .ant-collapse-header-text {
        display: flex;
        justify-content: center;
      }

      .ant-collapse-content-box {
        display: flex;
        flex-direction: column;
        grid-gap: 8px;
        padding: 12px !important;
      }
    }

    &.locl-collapse {
      border-radius: 4px;
      border: 1px solid var(--secondary-color-300);
      box-shadow: 0px 1px 2px 0px rgba(31, 41, 55, 0.08);
      background: var(--white-color-500);
      overflow: hidden;
    }

    .locl-location-page-spaces-container-filters__item__when,
    .locl-dropdown-inner-button {
      width: 100%;
      justify-content: space-between;

      &__value {
        text-align: left;
      }
    }

    .locl-location-page-spaces-container-filters__item__clear {
      width: 100%;
      text-align: center;
    }
  }
}

.locl-location-page-space-card {
  border-radius: 4px;
  border: 1px solid var(--secondary-color-400);
  background: var(--white-color-500);
  box-shadow: 0px 1px 2px 0px rgba(31, 41, 55, 0.08);
  padding: 8px;
  color: var(--secondary-color-500);
  height: max-content;

  &:hover {
    color: var(--secondary-color-500);
  }

  &__content {
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    max-width: 100%;
    height: 100%;
  }

  &__carousel {
    position: relative;
    width: 100%;
    max-width: 100%;

    .locl-card-carousel {
      min-width: 100%;
      height: 222px;
    }

    .locl-card-carousel__slide {
      width: 100% !important;
    }

    &:global(.swiper) {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__new-badge {
    position: absolute;
    top: 8px;
    right: 8px;
    border-radius: 4px;
    padding: 0px 6px;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    color: var(--danger-color-650);
    background: var(--danger-color-200);
    z-index: 20;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    grid-gap: 16px;
  }

  &__info-main {
    display: flex;
    flex-direction: column;
  }

  &__media-opportunity {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    color: var(--gray-color-500);
  }

  &__name {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    color: var(--primary-color-500);

    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }

  &__dimensions {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    color: var(--gray-color-500);
  }

  &__price {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    text-align: right;

    &__value {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      color: var(--primary-color-500);
    }

    &__indicate-fx {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px;
      color: var(--primary-color-700);
    }
  }
  
  &__footer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px;
    padding: 4px 0;
    margin-top: auto;
  }

  &__favourite-btn {
    width: 100%;
    
    svg {
      width: 20px;
      height: 20px;
      color: var(--danger-color-500);
    }
  }

  &__space-btn {
    &.locl-button {
      width: 100%;
    }
  }

  &__loading {
    &__carousel {
      width: 100% !important;
      height: 222px !important;
    }

    &__text {
      height: 28px !important;
    }

    &__dimensions {
      height: 26px !important;
    }

    &__button {
      height: 36px !important;
    }
  }
}

.locl-location-page-floor-plan-modal {
  width: 1190px !important;
  max-width: 1190px;

  --max-content-height: 65vh;

  :global {
    .ant-modal-header {
      padding: 24px 24px 16px 24px;
      margin: 0;
    }
    .ant-modal-content { padding: 0; }

    .ant-modal-body {
      padding: 0 24px 24px;
      min-width: 400px;
    }
  }
  
  .locl-modal__header-label {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }

  &__content {
    display: flex;
    grid-gap: 32px;
    width: 100%;
    position: relative;
  }

  &__img {
    flex: 1 1 0;
    display: flex;

    justify-content: center;

    .locl-floor-plan {
      width: max-content;
      padding: 0;
      overflow: hidden;

      &__image-map {
        max-height: var(--max-content-height);
      }

      &__map {
        max-width: 710px;
      }
    }
  }

  &__spaces-list {
    max-height: var(--max-content-height);
    overflow: auto;

    max-width: 384px;
    width: 100%;

    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px;

    align-items: flex-start;
    height: 100%;

    .locl-space-card {
      height: max-content;

      &__body {
        &__carousel {
          width: 110px;
          flex: 0 0 110px;
          height: 80px;
        }
      }
  
      &__price {
        white-space: normal;
  
        &__with-fx {
          min-width: auto;
          text-align: left;
        }
  
        &__indicate-fx {
          display: block;
          position: relative;
          text-align: left;
          top: auto;
          right: auto;
        }
      }
  
      &__main-info { grid-gap: 8px; }
  
      .locl-card-carousel { height: 80px; }
    }
  }
}


@media screen and (max-height: 900px) and (min-width: 1200px) {
  .locl-location-page-floor-plan-modal {
    --max-content-height: 60vh;
  }
}

@media screen and (max-height: 700px) and (min-width: 1200px) {
  .locl-location-page-floor-plan-modal {
    --max-content-height: 50vh;
  }
}

@media screen and (max-width: 1200px) {
  .locl-location-page-spaces-container-body {
   &__spaces-list {
      grid-template-columns: 1fr;
    }
  }
}

@media screen and (max-width: 1200px) {
  .locl-location-page-floor-plan-modal {
    width: max-content !important;

    &__spaces-list {
      display: none;
    }
  }
}

@media screen and (max-width: 920px) {
  .locl-location-page-spaces-container-body {
    height: auto;
    max-height: none;
  }
  
  .locl-location-page-spaces-container-filters {
    padding: 12px 0;
    margin: -20px 0 -12px 0;
    grid-gap: 12px;

    &__item {
      &__when {
        order: 0;

        &__overlay {
          :global {
            .ant-picker-panel-container {
              overflow: auto;
              max-height: calc(100vh - 200px);
            }
          }
        }
      }
      &__purpose {
        order: 1;
      }
      &__space-size {
        order: 2;
      }
      &__space-type {
        order: 3;
      }
      &__budget {
        order: 4;
      }
      &__clear {
        order: 5;
        width: 100%;
        text-align: center;
      }
    }
  }

  .locl-location-page-spaces-container {
    &__head {
      flex-direction: column;
      grid-gap: 12px;
      align-items: flex-start;
    }
  }
}

@media screen and (max-width: 720px) {
  .locl-location-page-floor-plan-modal {
    --max-content-height: none;
    
    width: 100vw !important;
    max-width: 100vw !important;
    margin: 0 !important;
    padding: 0;
    top: 0;  

    :global {
      .ant-modal-header {
        padding: 24px;
        margin: 0;
      }

      .ant-modal-content {
        height: var(--window-height, 100vh);
        display: flex;
        flex-direction: column;
        border-radius: 0;
      }

      .ant-modal-body {
        padding: 0 24px;
        min-width: 100%;
        flex: 1;
      }

      .react-transform-wrapper {
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;

        &:not(:last-child) {
          margin-top: 60px;
        }
      }

      .react-transform-component {
        min-width: 100%;
        min-height: 100%;
      }
    }

    &__content {
      height: 100%;
    }

    &__img {
      margin: 0 -24px;

      .locl-floor-plan {
        width: 100%;
        height: 100%;
        max-width: 100vw;
        padding: 0;
        overflow: auto;
        grid-gap: 24px;
        padding-bottom: 24px;

        &__name {
          padding: 0 24px;
        }

        &__content {
          flex: 1 1 0;
          max-height: calc(var(--window-height, 100vh) - 210px);
        }

        &__map {
          width: 100%;
          height: 100%;
        }

        &__image-map {
          max-height: none;
          object-fit: cover;
          width: 100%;
          height: 100%;
        }

        &__pagination {
          :global {
            .ant-pagination-item {
              width: 32px;
              height: 32px;
              flex: 0 0 32px;
            }
          }
        }

        :global {
          .react-transform-wrapper {
            &:not(:last-child) {
              margin-top: 0px;
            }
          }
        }
      }

      &__one-floor {
        .locl-floor-plan {
          padding-bottom: 0;

          &__content {
            flex: 1 1 0;
            max-height: calc(var(--window-height, 100vh) - 80px);
          }
        }
      }
    }

    .locl-modal__header-label {
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
    }
  }
}

@media screen and (max-width: 590px) {
  .locl-location-page-spaces-container {
    &__title {
      font-size: 20px;
      line-height: 32px;
    }
  }
}
