:import("@/assets/styles/shared/modal.module.scss") {
  locl-modal__header-label: locl-modal__header-label;
}

.locl-save-search-modal {
  width: 612px !important;

  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }

  &__controls {
    display: flex;
    column-gap: 8px;
    padding: 20px 0 12px;
    justify-content: flex-end;
  }

  :global {
    .ant-modal-header {
      padding: 24px;
      margin: 0;
    }
    .ant-modal-body {
      padding-top: 0;
      min-height: 180px;
      background: var(--white-color-500);
    }
  }

  .locl-modal__header-label {
    font-family: var(--main-font-family);
    font-size: 18px;
    line-height: 1.56;
  }

}

@media (max-width: 767px) {
  .locl-save-search-modal {
    &:global(.ant-modal) {
      width: 100vw !important;
      max-width: 100vw !important;
      margin: 0 !important;
      padding: 0;
      top: 0;

      :global {
        .ant-modal-content {
          border-radius: 0;

          display: flex;
          flex-direction: column;
        }

        .ant-modal-header { padding: 24px 12px; }

        .ant-modal-body {
          padding: 0 12px 24px;
          min-height: max-content;
          flex: 1;
        }
      }
    }

    &__wrapper {
      min-height: 100%;
    }

    &__controls {
      margin-top: auto;
      padding: 12px 0;

      :global {
        .ant-btn { width: 100%; }
      }
    }
  }
}
