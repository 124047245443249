:import("@/assets/styles/shared/modal.module.scss") {
  locl-modal: locl-modal;
  locl-modal__header-icon: locl-modal__header-icon;
  locl-modal__header-label: locl-modal__header-label;
}

:import("@/assets/styles/components/steps.module.scss") {
  locl-steps: locl-steps;
}

:import("@/assets/styles/shared/button.module.scss") {
  locl-button: locl-button;
}

.locl-llp-deals-modal {
  width: 630px !important;

  &.locl-modal {
    :global {
      .ant-modal-header {
        border-bottom: 1px solid var(--secondary-color-300);
        padding: 12px 16px;
      }
  
      .ant-modal-body {
        padding: 24px 16px 12px;
      }
    }
  }

  &__single-step {
    &.locl-modal {
      .locl-modal__header-icon {
        position: absolute;
        top: 12px;
        right: 16px;
      }
  
      .locl-llp-deals-modal__content__header-title {
        margin-right: 20px;
      }
  
      :global {
        .ant-modal-header {
          border-bottom: none !important;
          padding: 0 !important;
          height: 0 !important;
          background: transparent;
          border-bottom: none;
          height: 0;
        }
  
        .ant-modal-body {
          padding: 12px 16px;
        }
      }
    }
  }

  &__steps {
    &.locl-steps {
      padding: 0;
      gap: 12px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__steps {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;

      &__title {
        font-size: 14px;
        line-height: 20px;

        color: var(--gray-color-500);

        &_bold {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: var(--secondary-color-500);
        }
      }

      &__value {
        font-size: 16px;
        line-height: 24px;

        color: var(--secondary-color-500);
      }

      &__col-full {
        grid-column: 1 / -1;
      }
    }

    &__header {
      display: flex;
      justify-content: space-between;

      &__confirmation-button {
        &.locl-button {
          padding: 8px;

          :global {
            .ant-btn-icon {
              font-size: 20px;
            }
          }
        }
      }
    }
  
    &__header-title {
      font-size: 18px;
      line-height: 28px;
      font-weight: 500;

      color: var(--secondary-color-500);
    }

    &__header-subtitle {
      font-size: 14px;
      line-height: 20px;

      color: var(--gray-color-500);
    }

    &__main-info {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 16px;
      background: var(--gray-color-300);
      border-radius: 4px;

      &__image {
        border-radius: 8px;
        object-fit: cover;
      }

      &__block {
        display: flex;
        justify-content: space-between;
        width: 100%;
        min-height: 100px;
      }

      &__block-left,
      &__block-right {
        display: flex;
        flex-direction: column;
      }

      &__block-right {
        justify-content: space-between;
      }

      &__content {
        &__title {
          font-size: 18px;
          line-height: 28px;
          font-weight: 500;

          color: var(--secondary-color-500);
        }

        &__description {
          font-size: 14px;
          line-height: 20px;

          color: var(--gray-color-500);
        }

        &__price {
          display: flex;
          flex-direction: column;
          text-align: right;

          &__title {
            font-size: 14px;
            line-height: 20px;

            color: var(--gray-color-500);
          }

          &__value {
            font-size: 16px;
            line-height: 24px;

            color: var(--primary-color-900);
          }
        }

        &__period {
          margin-top: 8px;

          &__value {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: var(--secondary-color-500);
          }
        }
      }
    }

    &__show-more-controls {
      display: block;
      cursor: pointer;
  
      font-family: var(--main-font-family);
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-transform: lowercase;
      color: var(--danger-color-500);
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }

  &__footer-button {
    margin-left: auto;

    &__message {
      &.locl-button {
        padding: 8px;
      }
    }

    &__message-icon {
      width: 20px;
      height: 20px;
      flex: 0 0 20px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__footer-button-center {
    margin: 0 auto;
  }

  &__info {
    display: flex;
    flex-direction: column;
  }
}

.locl-llp-deals-modal-booking-info {
  &.locl-llp-deals-modal {
    padding-bottom: 0 !important;

    :global {
      .ant-modal-content {
        overflow: hidden;
      }

      .ant-modal-header {
        border-bottom: 0;
        padding: 16px;
        background: var(--gray-color-300);
      }
  
      .ant-modal-body {
        padding: 0px;
        background: var(--gray-color-300);
      }
    }

    .locl-modal__header-label {
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: 40px;
    }
  }

  .locl-llp-deals-modal {
    &__content {
      background: var(--white-color-500);
      padding: 24px 16px;
    }

    &__footer {
      background: var(--white-color-500);
      padding: 0 16px 12px;
    }
  }

  &__tabs {
    padding: 0 16px;
  }

  :global {
    .ant-tabs-top >.ant-tabs-nav::before {
      content: none !important;
    }
  }
}
