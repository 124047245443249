:import("@/assets/styles/shared/button.module.scss") {
  locl-button: locl-button;
 }

.landlord-messages-nudge-modal {
  :global {
    .ant-modal-content { min-width: 620px; }
  }

  &__form {
    margin-top: 18px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    row-gap: 24px;
  }

  &__textarea { grid-column: 1/-1; }

  &__buttons {
    margin-top: 12px;

    display: flex;
    grid-column: 1/-1;
    align-items: center;
    justify-content: center;
    gap: 16px;

    &__cancel {
      &.locl-button {
        color: var(--gray-color-500);
        font-weight: 600;

        &:global(.ant-btn:not(:disabled):not(.ant-btn-disabled):hover) {
          color: var(--gray-color-500); 
        }
      }
    }
  }
}
